import * as React from 'react';
export const useSkeletonContextValues = (state)=>{
    const { animation, appearance } = state;
    const skeletonGroup = React.useMemo(()=>({
            animation,
            appearance
        }), [
        animation,
        appearance
    ]);
    return {
        skeletonGroup
    };
};
