import { mergeClasses } from '@griffel/react';
export const skeletonClassNames = {
    root: 'fui-Skeleton'
};
/**
 * Apply styling to the Skeleton slots based on the state
 */ export const useSkeletonStyles_unstable = (state)=>{
    state.root.className = mergeClasses(skeletonClassNames.root, state.root.className);
    return state;
};
