.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 20px;
}

.container > * {
  width: 100%;
  max-width: 500px;
}
