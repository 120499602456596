import { Types, getGroupper } from 'tabster';
import { useTabsterAttributes } from './useTabsterAttributes';
import { useTabster } from './useTabster';
/**
 * A hook that returns the necessary tabster attributes to support groupping.
 * @param options - Options to configure keyboard navigation
 */ export const useFocusableGroup = (options)=>{
    const tabster = useTabster();
    if (tabster) {
        getGroupper(tabster);
    }
    return useTabsterAttributes({
        groupper: {
            tabbability: getTabbability(options === null || options === void 0 ? void 0 : options.tabBehavior)
        },
        focusable: {
            ignoreKeydown: options === null || options === void 0 ? void 0 : options.ignoreDefaultKeydown
        }
    });
};
const getTabbability = (tabBehavior)=>{
    switch(tabBehavior){
        case 'unlimited':
            return Types.GroupperTabbabilities.Unlimited;
        case 'limited':
            return Types.GroupperTabbabilities.Limited;
        case 'limited-trap-focus':
            return Types.GroupperTabbabilities.LimitedTrapFocus;
        default:
            return undefined;
    }
};
